import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import './TransparentVideo.scss';

function TransparentVideo({
  width,
  height,
  backgroundRef,
  mp4Src,
  webmSrc,
  containerClass,
  useFallBack,
  fallBack,
  ...rest
}) {
  const container = useRef();
  const videoRef = useRef();

  const safariAgent =
    typeof window !== 'undefined' &&
    navigator?.userAgent?.search('Safari') >= 0 &&
    navigator?.userAgent?.search('Chrome') < 0;
  const videoSrc = safariAgent ? mp4Src : webmSrc || mp4Src;

  useEffect(() => {
    const vid = videoRef.current;
    vid.addEventListener('loadedmetadata', () => {
      const videoUsed = (videoSrc || '').includes('webm') ? 'webm' : 'mp4';
      container.current.classList.add('loaded');
      container.current.classList.add(`video-${videoUsed}`);
    });
    function autoPlay() {
      const fixAutoPlay = () => {
        // Remove events
        document.removeEventListener('touchstart', fixAutoPlay);
        document.removeEventListener('touchend', fixAutoPlay);
        document.removeEventListener('scroll', fixAutoPlay);
      };
      // iOS 6-8
      document.addEventListener('touchstart', fixAutoPlay);
      // iOS 9
      document.addEventListener('touchend', fixAutoPlay);
      // Scroll (Just in case for Desktop)
      document.addEventListener('scroll', fixAutoPlay);
    }

    if (!safariAgent || !vid) {
      vid.src = videoSrc;
      if (!document.hidden) vid.play();
      autoPlay();
      return () => {};
    }
    window?.document?.body?.classList.add('safari');

    vid.src = videoSrc;
    if (!document.hidden) vid.play();
    /**
     * fix for autoplay not working
     */
    autoPlay();

    /**
     * Extracts background color of video and set to backgroundElement
     */
    function setVideoBgColor(backgroundElement) {
      const canvasBg = document.createElement('canvas');
      canvasBg.width = 8;
      canvasBg.height = 8;

      const ctxBg = canvasBg.getContext('2d');
      ctxBg.drawImage(vid, 0, 0, 8, 8);

      const p = ctxBg.getImageData(0, 0, 8, 8).data;
      if (p[60] === 0 && p[61] === 0 && p[62] === 0) return;
      // dont take the first but fourth pixel [r g b]
      // eslint-disable-next-line no-param-reassign
      backgroundElement.style.backgroundColor = `rgb(${p[60]},${p[61]},${p[62]})`;
    }

    vid.addEventListener(
      'loadeddata',
      () => {
        if (useFallBack && fallBack.length) {
          const elm = backgroundRef.current || container.current;
          if (elm) elm.style.backgroundColor = fallBack;
          return;
        }
        if (backgroundRef.current || container.current)
          setVideoBgColor(backgroundRef.current || container.current);
        if (!document.hidden) vid.play();
      },
      false,
    );
    return () => {};
  }, []);
  return (
    <div className={`transparent-video ${containerClass}`} ref={container}>
      <video autoPlay loop muted playsInline ref={videoRef} {...rest} />
    </div>
  );
}
TransparentVideo.defaultProps = {
  webmSrc: null,
  backgroundRef: null,
  width: null,
  height: null,
  containerClass: '',
  useFallBack: false,
  fallBack: '',
};
TransparentVideo.propTypes = {
  mp4Src: PropTypes.string.isRequired,
  webmSrc: PropTypes.string,
  containerClass: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  backgroundRef: PropTypes.any,
  width: PropTypes.string,
  height: PropTypes.string,
  fallBack: PropTypes.string,
  useFallBack: PropTypes.bool,
};
export default TransparentVideo;

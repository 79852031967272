import React from 'react';
import CheckList from '../components/CheckList/CheckList';
import TabPanel from '../components/Tabs/TabPanel';

export const heroCheckList = [
  {
    id: 0,
    listItem: '24*7 real-time verification',
  },
  {
    id: 1,
    listItem: '69% less onboarding drop-off',
  },
  {
    id: 2,
    listItem: '5 sec-authentication',
  },
];
export const heroLogoData = [
  {
    id: 0,
    src: 'zomato.png',
    width: '257',
    height: '55',
    className: 'w-[85px]',
    alt: 'zomato',
  },
  {
    id: 1,
    src: 'meesho.png',
    width: '214',
    height: '66',
    className: 'w-[107px]',
    alt: 'meesho',
  },
  {
    id: 2,
    src: 'dunzo-black.png',
    width: '247',
    height: '72',
    className: 'w-[82px]',
    alt: 'Dunzo',
  },
  {
    id: 3,
    src: 'bajajfinserv.png',
    width: '214',
    height: '57',
    className: 'w-[92px]',
    alt: 'Bajaj Finserv',
  },
  {
    id: 4,
    src: 'rapido.png',
    width: '175',
    height: '96',
    className: 'w-[64px]',
    alt: 'Rapido',
  },
  {
    id: 5,
    src: 'quicko.png',
    width: '182',
    height: '42',
    className: 'w-[91px]',
    alt: 'Quicko',
  },
  {
    id: 6,
    src: 'bookmyforex.png',
    width: '307',
    height: '78',
    className: 'w-[112px]',
    alt: 'bookmyforex',
  },
  // {
  //   id: 7,
  //   src: 'signzy.png',
  //   width: '309',
  //   height: '90',
  //   className: 'w-[102px]',
  //   alt: 'Signzy',
  // },
];

export const heroSliderSettings = {
  dots: false,
  arrows: false,
  infinite: false,
  autoplay: false,
  autoplaySpeed: 0,
  swipe: false,
  pauseOnHover: false,
  draggable: false,
  centerMode: false,
  speed: 2000,
  slidesToShow: heroLogoData.length,
  variableWidth: true,
  cssEase: 'linear',
  responsive: [
    {
      breakpoint: 1120,
      settings: {
        rtl: true,
        draggable: false,
        autoPlay: true,
        autoplay: true,
        speed: 2000,
        swipe: false,
        autoplaySpeed: 2000,
        cssEase: 'linear',
        slidesToShow: heroLogoData.length - 2,
        infinite: true,
        centerMode: false,
        variableWidth: true,
      },
    },
  ],
};

const headingClass = 'mb-2 md:mb-[12px]';
const contentBodyMarginClass = 'mb-1 md:mb-8 max-w-[550px]';
const imageClassName = 'w-full md:pr-[18px]';
const alignStart = true;
const contentClassName = 'md:pl-[19px] mt-0 lg:pt-[60px] md:pt-[30px]';
export const verificationSuiteData = [
  {
    key: 0,
    heading: 'Bank Account Verification',

    content: (
      <div className="md:mt-[60px]">
        <TabPanel
          headingClass={headingClass}
          img={{
            src: '/img/verification-suite/suite-1.svg',
            width: '610',
            height: '478',
          }}
          heading="Bank account Verification"
          imageClassName={imageClassName}
          alignStart={alignStart}
          contentClassName={contentClassName}
          body={
            <>
              <div className={contentBodyMarginClass}>
                Verify if the bank account exists and match the name provided by
                the user with the account holder&apos;s name at the bank. Ensure
                secure onboarding of users and minimise failed or transfer
                reversals. Bank Account Verification support account
                verifications of 600+ registered banks.
                <CheckList
                  className="mt-6"
                  itemMargin="mb-3"
                  fontClass="text-2.5sm"
                  data={[
                    {
                      id: 0,
                      text:
                        'Verify users before account opening or during onboarding KYC ',
                    },
                    {
                      id: 1,
                      text: 'Verify users as a part of refunds redemption flow',
                    },
                    {
                      id: 2,
                      text: 'Verify before making Payouts or disbursing loans',
                    },
                  ]}
                />
              </div>
            </>
          }
          footer={
            <>
              <div className="bg-cf-primary px-4 pt-[33px] pb-3 text-base text-white rounded relative mt-4 border border-cf-stroke max-w-[500px] ml-[4px] md:ml-0">
                <div className="ribbon-purple">Did you know</div>
                <span className="block mt-1 text-2.5sm leading-[24px]">
                  Incorrect bank account details are the reason for &gt; 60% of
                  bank transfer failures.
                </span>
              </div>
              <a
                className="button button-green mt-8 md:mt-10 wide w-full md:w-auto"
                href="!#">
                Learn More <span className="chevron" />
              </a>
            </>
          }
        />
      </div>
    ),
  },
  {
    key: 1,
    heading: 'UPI ID Verification',

    content: (
      <div className="md:mt-[60px]">
        <TabPanel
          headingClass={headingClass}
          img={{
            src: '/img/verification-suite/suite-2.svg',
            width: '610',
            height: '478',
          }}
          heading="UPI ID Verification "
          imageClassName={imageClassName}
          alignStart={alignStart}
          contentClassName={contentClassName}
          body={
            <>
              <div className={contentBodyMarginClass}>
                Enter UPI ID or UPI VPA to check its validity. You can also
                validate the customer name against the valid VPA or UPI IDs.
              </div>
            </>
          }
          footer={
            <a
              className="button button-green mt-8 md:mt-0 wide w-full md:w-auto"
              href="!#">
              Learn More <span className="chevron" />
            </a>
          }
        />
      </div>
    ),
  },
  {
    key: 2,
    heading: 'IFSC Verification',

    content: (
      <div className="md:mt-[60px]">
        <TabPanel
          headingClass={headingClass}
          img={{
            src: '/img/verification-suite/suite-3.svg',
            width: '610',
            height: '478',
          }}
          heading="IFSC Verification"
          imageClassName={imageClassName}
          alignStart={alignStart}
          contentClassName={contentClassName}
          body={
            <>
              <div className={contentBodyMarginClass}>
                The most exhaustive list of IFSC codes updated weekly. Verify
                whether the IFSC is valid, the bank name and bank branch that it
                belongs to.
              </div>
            </>
          }
          footer={
            <>
              <div className="bg-cf-primary px-4 pt-[33px] pb-3 text-base text-white rounded relative mt-4 border border-cf-stroke max-w-[500px] ml-[4px] md:ml-0">
                <div className="ribbon-purple">Bonus</div>
                <span className="block mt-1 text-2.5sm leading-[24px]">
                  Know the transfer modes supported by an IFSC -{' '}
                  <b>NEFT, IMPS, or RTGS</b> and choose your payout method
                  accordingly.
                </span>
              </div>
              <a
                className="button button-green mt-8 md:mt-10 wide w-full md:w-auto"
                href="!#">
                Learn More <span className="chevron" />
              </a>
            </>
          }
        />
      </div>
    ),
  },
  {
    key: 3,
    heading: 'PAN Verification',

    content: (
      <div className="md:mt-[60px]">
        <TabPanel
          headingClass={headingClass}
          img={{
            src: '/img/verification-suite/suite-4.svg',
            width: '610',
            height: '478',
          }}
          heading="PAN Verification"
          imageClassName={imageClassName}
          alignStart={alignStart}
          contentClassName={contentClassName}
          body={
            <>
              <div className={contentBodyMarginClass}>
                Verify if the PAN is valid, identify the registered name of the
                cardholder, along with the type of PAN- individual or business
                against the latest updated PAN database.
                <CheckList
                  className="mt-6"
                  itemMargin="mb-3"
                  fontClass="text-2.5sm"
                  data={[
                    {
                      id: 0,
                      text: 'Verify the PAN type',
                    },
                    {
                      id: 1,
                      text: 'Match the name as per NSDL database',
                    },
                    {
                      id: 2,
                      text: 'Verify if Aadhaar is liked to the PAN',
                    },
                  ]}
                />
              </div>
            </>
          }
          footer={
            <a
              className="button button-green mt-8 md:mt-0 wide w-full md:w-auto"
              href="!#">
              Learn More <span className="chevron" />
            </a>
          }
        />
      </div>
    ),
  },
  {
    key: 4,
    heading: 'Aadhaar Verification',
    content: (
      <div className="md:mt-[60px]">
        <TabPanel
          headingClass={headingClass}
          img={{
            src: '/img/verification-suite/suite-5.png',
            width: '610',
            height: '478',
          }}
          heading="Aadhaar Verification"
          imageClassName={imageClassName}
          alignStart={alignStart}
          contentClassName={contentClassName}
          body={
            <>
              <div className={contentBodyMarginClass}>
                Validate the user&apos;s 12-digit Aadhaar number. Get details
                like the registered name, gender, date of birth, address, state,
                user&apos;s image, father&apos;s name, contact number and email
                address in seconds.
                <div className="max-w-[474px]">
                  <CheckList
                    className="mt-6"
                    itemMargin="mb-3"
                    fontClass="text-2.5sm"
                    data={[
                      {
                        id: 0,
                        text:
                          'Get user’s consent by enabling OTP-based Verification',
                      },
                      {
                        id: 1,
                        text: 'Verify address ',
                      },
                      {
                        id: 2,
                        text: 'Match name as per UIDAI records',
                      },
                      {
                        id: 3,
                        text:
                          'With Aadhar OCR extract data from Aadhaar cards instantly. Use API to auto-fill this data in the correct fields for a quicker & errorless form-filling experience',
                      },
                    ]}
                  />
                </div>
              </div>
            </>
          }
          footer={
            <a
              className="button button-green mt-8 md:mt-0 w-full md:w-auto wide"
              href="!#">
              Learn More <span className="chevron" />
            </a>
          }
        />
      </div>
    ),
  },
  {
    key: 5,
    heading: 'GSTIN Verification',
    content: (
      <div className="md:mt-[60px]">
        <TabPanel
          headingClass={headingClass}
          img={{
            src: '/img/verification-suite/suite-6.svg',
            width: '610',
            height: '478',
          }}
          heading="GSTIN Verification"
          imageClassName={imageClassName}
          alignStart={alignStart}
          contentClassName={contentClassName}
          body={
            <>
              <div className={contentBodyMarginClass}>
                Enter the 15-digit GSTIN number and get details like GSTIN
                validity, GSTIN status, registered business name, nature of
                business, and business address, in seconds.
                <CheckList
                  className="mt-6"
                  itemMargin="mb-3"
                  fontClass="text-2.5sm"
                  data={[
                    {
                      id: 0,
                      text: 'Verify registered business address ',
                    },
                    {
                      id: 1,
                      text: 'Match name as per GSTIN records',
                    },
                    {
                      id: 2,
                      text: 'Ensure accurate ITC claim everytime',
                    },
                  ]}
                />
              </div>
            </>
          }
          footer={
            <a
              className="button button-green mt-8 md:mt-0 w-full md:w-auto wide"
              href="!#">
              Learn More <span className="chevron" />
            </a>
          }
        />
      </div>
    ),
  },
];

export const usecasesData = [
  {
    id: 0,
    image: {
      src: '/img/tata-aig.png',
      width: '130',
      height: '96',
    },
    background: '#01458C',
    title: 'Financing solution with faster verification',
    description:
      'Tata AIG, a leading General Insurance company has integrated Bank Account Verification APIs with the Name match feature as a part of their overall KYC process to reduce fraud and control disbursements of claims.',
  },
  {
    id: 1,
    image: {
      src: '/img/bajajfinserv-logo.png',
      width: '140',
      height: '44',
    },
    background: '#0071BB',
    title: 'Automated User KYC and bank account check',
    description:
      'Baja Finance verifies it’s customers before activating any of their products like loans, insurance, FD’s or before loan disbursals for personal, business or professional requirement.',
  },
  {
    id: 2,
    image: {
      src: '/img/junio.png',
      width: '120',
      height: '27',
    },
    background: '#8700F3',
    title: 'Faster KYC Verification for Smarter Solution',
    description:
      'Facilitating over 5 Lakhs+ users with Smart Card solution for children, Junio has integrated Verification Suite APIs with their existing platform to automate the overall onboarding KYC process.',
  },
  // {
  //   id: 3,
  //   image: {
  //     src: '/img/signzy-logo.png',
  //     width: '186',
  //     height: '54',
  //   },
  //   background: '#000000',
  //   title: 'Reliable E-KYC for Signzy',
  //   description:
  //     'Signzy trusts Cashfree Payments for reliable and real-time verification of bank account numbers as a part of its KYC suite.',
  // },
  {
    id: 4,
    image: {
      src: '/img/zomato-white.svg',
      width: 151,
    },
    background: '#E23744',
    title: 'Seamless delivery partner onboarding for Zomato',
    description:
      'Zomato ensures quick and correct on-boarding of riders using Cashfree’s API.',
  },
  {
    id: 5,
    image: {
      src: '/img/delhivery-light.png',
      width: '161',
      height: '43',
    },
    background: '#000000',
    title: 'Hasslefree onboarding for Delhivery',
    description:
      'Delhivery, India’s most trusted shipment company onboards thousands of individuals, who ensures quick and seamless delivery of happiness. With robust KYC Verification APIs, Delhivery reduces the turnaround time and onboards employees remotely.',
  },
];
export const instantKycCard = [
  {
    id: 0,
    icon: '/img/icons/fast-settlements-green.svg',
    heading: 'Instantly verify and save operational cost',
    description: (
      <>
        Verify individual or business identity instantly and reduce the
        onboarding cost by embedding{' '}
        <h2 className="inline font-body">KYC Verification API</h2> with your
        product/app. Ensure no drop-off with faster verification.
      </>
    ),
  },
  {
    id: 1,
    icon: '/img/icons/fraud-purple.svg',
    heading: 'Fraud and Risk Reduction',
    description:
      'Eliminate fraud and build successful business relations with trusted individuals and businesses. Match data from the latest database updated weekly.',
  },
  {
    id: 2,
    icon: '/img/icons/highly-customisable-pink.svg',
    heading: 'Know the reasons for verification failure',
    description:
      'Verification can fail due to any invalid or fake details. Know exactly why verification failed in real-time.',
  },

  {
    id: 3,
    icon: '/img/icons/yellow-approval-large.svg',
    heading: 'Inbuilt approval flow',
    description:
      'Assign maker and checker roles to reduce human errors and possible fraud for higher accuracy.',
  },
  {
    id: 4,
    icon: '/img/icons/high-success-rates-pink.svg',
    heading: <h2 className="font-body">Bulk Identity Verification</h2>,
    description:
      'Do a single verification or 10,000+ verifications in one go, via simple excel upload or integrate with APIs.',
  },
  {
    id: 5,
    icon: '/img/icons/insurance-purple.svg',
    heading: 'Fuzzy Matching',
    description:
      'Verify users and find the matches that may be less than 100% perfect but has a string match. Get the results for match percentage and exact details as per the updated database.',
  },
];

export const instantKycCheckList = [
  {
    id: 0,
    text: '5 Lakh+ API calls per day',
  },
  {
    id: 1,
    text: '85% reduction in operational time',
  },
  {
    id: 2,
    text: '75% reduction in operational cost',
  },
];

export const integrationCheckList = [
  {
    id: 0,
    item:
      'Verify sensitive user information with highly reliable and secure APIs',
  },
  {
    id: 1,
    item: 'Supports Sync and Async program',
  },
  {
    id: 2,
    item:
      'Use the official Cashfree Payments libraries for different programming languages to integrate with your product and automate the verification flow',
  },
  {
    id: 3,
    item:
      'Get notified on single or bulk verification status in real-time via webhooks',
  },
];
export const faqData = [
  {
    id: 0,
    q: 'How much time does KYC Verification take?',
    a: (
      <p>
        On Cashfree Payments, you can instantly verify Bank Account Number, UPI
        ID, IFSC, PAN, etc. As you submit the request, our API gives an instant
        response.
      </p>
    ),
  },
  {
    id: 1,
    q:
      'Is there any limit on the total number of verification requests per day?',
    a: (
      <p>
        No, there is no such limit. You can use the feature to verify any number
        of accounts in a day, 24*7.
      </p>
    ),
  },
  {
    id: 2,
    q: 'How can I use the Bank Account Verification API for KYC Verification?',
    a: (
      <p>
        While onboarding users, vendors, delivery partners, or even employees,
        an organization needs to do a Bank Account check. Minimize the chance of
        exposing the data to any third party, integrate Bank Account
        Verification API with the company’s internal systems and automated the
        process.
      </p>
    ),
  },
  {
    id: 3,
    q: 'What are the other use cases of the Verification Suite?',
    a: (
      <div>
        <p>
          The capability to verify a/c can be used in multiple business
          scenarios such as
        </p>
        <ul className="list-decimal">
          <li>
            Employee background Verification: a recruitment agency can use the
            feature for a candidate&apos;s background check
          </li>
          <li>
            Vendor onboarding: a marketplace splitting commission with different
            vendors can use the feature to verify the bank account, PAN or other
            details at the time of onboarding a vendor. This also helps in
            making accurate payments later on. The ability to integrate the API
            with existing ERP helps reduce operational effort while saving
            costs.
          </li>
        </ul>
      </div>
    ),
  },
  {
    id: 4,
    q:
      'I am planning to opt for Cashfree Payouts for bulk disbursals. Do I need to go for a verification API separately?',
    a: (
      <p>
        Yes, Payouts users can integrate the Verification Suite to avail this
        feature.
      </p>
    ),
  },
  {
    id: 5,
    q:
      'I want to use Cashfree Payments marketplace settlement for paying vendors. Do I need to take the verification feature separately?',
    a: (
      <p>
        Yes, if you are using Cashfree marketplace settlement and want to use
        identity verification API as well, you need to opt for the feature
        separately. Our experts will help you with the integration and you will
        be able to use Cashfree marketplace settlement and verification features
        together.
      </p>
    ),
  },
  {
    id: 6,
    q: 'How many banks are covered under BAV?',
    a: (
      <>
        <p>
          The verification can be done for 600+ recognized Indian banks
          including all public banks, private banks, and 126+ co-operative
          banks*.
        </p>
        <p>
          Note: Verification may not be possible for some co-operative bank a/cs
        </p>
      </>
    ),
  },
  {
    id: 7,
    q: 'How reliable is the information verified?',
    a: (
      <p>
        The data is directly fetched from the updated government databases,
        hence is highly reliable/accurate
      </p>
    ),
  },
];
export const otherProducts = [
  {
    id: 0,
    title: 'Payouts',
    body:
      'Connect Verification Suite with Payouts and do accurate payouts every time 24*7.',
    url: 'https://www.cashfree.com/payouts/',
    iconname: '/img/icons/payouts-green.svg',
  },
  {
    id: 1,
    title: 'Cashgram',
    body: `Payout links enable you to send money without the receiver’s bank account details or UPI ID, Verify the beneficiaries' details to ensure money is credited to intended beneficiaries only.`,
    url: 'https://www.cashfree.com/cashgram/',
    iconname: '/img/icons/cashgram.svg',
  },
  {
    id: 2,
    title: 'Easy Split',
    body:
      'Verify and onboard vendors, collect payments from customers, deduct commissions, and split payments between vendors or your own bank accounts.',
    url: 'https://www.cashfree.com/easy-split/split-payment-gateway',
    iconname: '/img/icons/easy-split.svg',
  },
  {
    id: 3,
    title: 'Payment Gateway',
    body:
      'Collect payments on your website or app. Verify account details before doing customer refund.',
    url: 'https://www.cashfree.com/payment-gateway-india/',
    iconname: '/img/icons/payment-gateway-2.svg',
  },
];

import React from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import './SuiteSlider.scss';
import CheckList from '../../CheckList/CheckList';
import SliderButton from '../../SliderEasySplit/SliderButton';

const verificationSuiteKycData = [
  {
    id: 0,
    title: 'Banks',
    text:
      'Fastrack account opening, KYC/KYB Verification, loan disbursal, overdue or allocating credit cards. Create background verification journeys without writing a single line of code. Verify the account before disbursement to minimize transfer failure or transfer reversals.',
    image: {
      width: '60',
      height: '60',
      src: '/img/verification-suite/icons/green-banks.svg',
    },
    checkList: [
      {
        id: 0,
        text: 'Loan Disbursals',
      },
      {
        id: 1,
        text: <>Credit Cards&nbsp;allocations</>,
      },
      {
        id: 2,
        text: 'KYC/KYB Verification',
      },
      {
        id: 3,
        text: 'Payouts',
      },
    ],
  },
  {
    id: 1,
    title: 'Investments',
    text:
      'Help your users get started on your platform faster with PAN and Aadhar verification via OCR which reduces the turnaround time by 75%. Explore a vast range of  Identity verification APIs that are highly customisable and easy to integrate.',
    image: {
      width: '60',
      height: '60',
      src: '/img/verification-suite/icons/purple-bag-investments.svg',
    },
    checkList: [
      {
        id: 0,
        text: 'Stock market',
      },
      {
        id: 1,
        text: 'Mutual funds',
      },
      {
        id: 2,
        text: 'Fixed deposits',
      },
    ],
  },
  {
    id: 2,
    title: 'Retail Lending',
    text:
      "Highly customisable KYC Verification API for Lending platforms enables you to capture details like the borrowers' name, age, address, user's image, father's name and other relevant documents.",
    image: {
      width: '60',
      height: '60',
      src: '/img/verification-suite/icons/retail-lending.svg',
    },
    checkList: [
      {
        id: 0,
        text: <>Reduce loan disbursal&nbsp;time</>,
      },
      {
        id: 1,
        text: <>Reduce transfer failures &&nbsp;reversals</>,
      },
      {
        id: 2,
        text: <>Avoid fraudulent&nbsp;identities</>,
      },
    ],
  },
  {
    id: 3,
    title: 'Fintechs and Neo banks',
    text:
      'Complete customer or business KYC Verification within 30 seconds. Use KYC/KYB Verification Suite to verify users/businesses and fastrack onboarding and before making payouts to ensure zero transfer reversals or failures due to incorrect account number.',
    image: {
      width: '60',
      height: '60',
      src: '/img/verification-suite/icons/fintech.svg',
    },
    checkList: [
      {
        id: 0,
        text: 'Onboard users',
      },
      {
        id: 1,
        text: 'Disburse loans',
      },
      {
        id: 2,
        text: <>Issue credit or prepaid&nbsp;cards</>,
      },
    ],
  },
  {
    id: 4,
    title: 'Ecommerce, Hyperlocal & logistics businesses',
    text:
      'Build fastest onboarding system to onboard your delivery partners, vendors, contractors or employees. Onboard remotely, create a hassle-free digital journey.',
    image: {
      width: '60',
      height: '60',
      src: '/img/verification-suite/icons/ecommerce-tag.svg',
    },
    checkList: [
      {
        id: 0,
        text: <>Verify address via Aadhaar/GSTIN&nbsp;verification</>,
      },
      {
        id: 1,
        text: <>Match the registered name during&nbsp;verification</>,
      },
      {
        id: 2,
        text: <>No code solution &&nbsp;APIs</>,
      },
    ],
  },
  {
    id: 5,
    title: 'HR and Staffing Platforms',
    text: (
      <>
        Build a scalable and reliable network with KYC Verification Suite.
        Ensure zero frauds and identity thefts, onboard only authenticated
        employees across divisions, locations and cities without physical
        interactions. Customise and integrate Verification Suite APIs with your
        existing HRMS&nbsp;tools.
      </>
    ),
    image: {
      width: '60',
      height: '60',
      src: '/img/verification-suite/icons/hr-staffing.svg',
    },
    checkList: [
      {
        id: 0,
        text: 'Onboard employees remotely',
      },
      {
        id: 1,
        text: 'Avoid identity thefts',
      },
      {
        id: 2,
        text: <>Customisable APIs and&nbsp;workflow</>,
      },
    ],
  },
  {
    id: 6,
    title: 'Online Gaming',
    text:
      'In gaming industry, there are high chances of identity theft, fraud, underage users, identity cloning, data theft and data breaches. With Cashfree Payments ensure automate the player onboarding and ensure quick money transfer with Payouts with automated verification.',
    image: {
      width: '60',
      height: '60',
      src: '/img/verification-suite/icons/online-gaming.svg',
    },
    checkList: [
      {
        id: 0,
        text: <>Real-time bank A/c, age & address&nbsp;verification</>,
      },
      {
        id: 1,
        text: <>Secure disbursal of rewards and&nbsp;cashback</>,
      },
      {
        id: 2,
        text: <>Custom API verification&nbsp;flow</>,
      },
    ],
  },
];
function SuiteSlider({ data, settings }) {
  return (
    <div className="suite-slider-slick ml-[5%] md:ml-[7.5px] mb-[60px] md:mb-[48px]">
      <Slider {...settings}>
        {data.map((d) => (
          <div key={d.id} className="w-full md:px-[19px] !flex h-full group">
            <div className="flex flex-col md:justify-between w-full p-[24px] md:p-[48px] suite-card">
              <div className="mb-6 md:mb-10">
                <div className="w-[40px] h-[40px]">
                  <img alt="" {...d.image} className="w-full" />
                </div>
                <div className="text-md md:text-3md my-2 md:mt-5 md:mb-1 font-semibold">
                  {d.title}
                </div>
                <div className="text-2sm md:text-base md:w-11/12">{d.text}</div>
              </div>
              <div className="-mt-4">
                <CheckList
                  className="flex flex-col md:flex-row"
                  itemMargin="mb-0 pt-4 pr-4"
                  fontClass="text-2.5sm font-semibold"
                  data={d.checkList}
                />
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}
SuiteSlider.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      text: PropTypes.string,
      image: PropTypes.shape({}),
      checkList: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          text: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
        }),
      ),
    }),
  ),
  settings: PropTypes.shape({}),
};

SuiteSlider.defaultProps = {
  data: verificationSuiteKycData,
  settings: {
    dots: true,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1.4,
    nextArrow: <SliderButton left={false} />,
    prevArrow: <SliderButton />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          infinite: false,
          arrows: false,
          dots: true,
        },
      },
    ],
  },
};

export default SuiteSlider;

import { useState, useCallback, useEffect } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);
// Duration to stay on the tab 15sec
const DURATION = 15;

const useTabAnimation = (timerAutoPlay, ref, hovered, changeActiveTab) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const setActiveTabIndex = useCallback((i) => {
    setActiveIndex(i);
  }, []);
  useEffect(() => {
    const tl = gsap.timeline({
      paused: false,
      scrollTrigger: {
        trigger: ref.current,
        start: 'top bottom',
        end: 'bottom top',
        // markers: true,
        toggleActions: 'restart pause restart pause',
      },
    });
    const mm = gsap.matchMedia();
    // Tabs only visible at 767px reset will kill timeline not needed
    mm.add('(max-width:767px)', () => {
      // Getting current tab
      if (timerAutoPlay) {
        const elm = ref.current?.children[activeIndex]?.querySelector(
          '.progress',
        );
        if (elm && !hovered) {
          // will animate progress from 0 to 100% on not hovered tabs
          tl.fromTo(
            elm,
            {
              translateX: '-100%',
            },
            {
              translateX: '0%',
              duration: DURATION,
              ease: 'linear',
              onComplete: () => {
                // console.log('triggered');
                changeActiveTab();
              },
            },
          );
        } else if (hovered && elm) {
          // will animate progress to 100% on hovered tab state
          tl.fromTo(
            elm,
            { translateX: '-100%' },
            { translateX: '0%', duration: 0.5 },
          );
        }
      }
      return () => {
        tl.kill();
      };
    });

    return () => {
      mm.kill();
    };
  }, [activeIndex, hovered]);
  return { setActiveTabIndex };
};
export default useTabAnimation;

import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../Icon';

import './GetStarted.scss';

const GetStarted = ({
  heading,
  content,
  checkList,
  startNowLink,
  getInTouchLink,
}) => (
  <section className="get-started">
    <div className="container position-relative" style={{ zIndex: '1' }}>
      <div className="row row-no-margin jistify-flex-start tablet-wrap">
        <div className="column" style={{ padding: 0 }}>
          <div className="font-heading text-xl font-semibold text-inherit">
            {heading}
          </div>
          <p>{content}</p>
          <ul className="check-list-round">
            {checkList.map(({ id, listItem }) => (
              <li key={id}>
                <span
                  style={{ backgroundColor: 'rgba(255, 255, 255, 0.2451)' }}>
                  <Icon
                    name="tick"
                    width="9.21px"
                    height="6.29px"
                    fill="#fff"
                  />
                </span>
                <p>{listItem}</p>
              </li>
            ))}
          </ul>
        </div>
        <div className="row row-no-margin buttons">
          <a
            href={startNowLink}
            target="_blank"
            rel="noopener noreferrer"
            className="button button-green">
            Create Account
            <span className="chevron" />
          </a>
          <a
            href={getInTouchLink}
            target="_blank"
            rel="noopener noreferrer"
            className="button button-outline no-shadow">
            Contact Sales
            <span className="chevron" />
          </a>
        </div>
      </div>
    </div>
  </section>
);

GetStarted.propTypes = {
  heading: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  checkList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      btnColor: PropTypes.string,
    }),
  ),
  startNowLink: PropTypes.string,
  getInTouchLink: PropTypes.string,
};

GetStarted.defaultProps = {
  heading: `Ready to get started?`,
  content: `With Cashfree Payments, collect payments, make payouts, manage
  international payments and more. Start now by creating your account
  or get in touch to explore custom solutions.`,
  checkList: [
    {
      id: 0,
      listItem: 'Easy onboarding',
    },
    {
      id: 1,
      listItem: 'Dedicated Account Manager',
    },
    {
      id: 2,
      listItem: 'API access',
    },
    // {
    //   id: 3,
    //   listItem: '24x7 support',
    // },
  ],
  startNowLink:
    'https://merchant.cashfree.com/merchants/signup?source-action=Home%20page&action=Sign%20Up&button-id=StartNow_Footer',
  getInTouchLink:
    'https://www.cashfree.com/contact-sales?source-action=Home%20page&action=Contact%20Sales&button-id=ContactSales_Footer',
};

export default GetStarted;

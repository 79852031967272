import React from 'react';
import PropType from 'prop-types';
import Slider from 'react-slick';
import Icon from '../../Icon';
import Code from '../../Code';
import './IntegrationSection.scss';

const terminalLine = '!text-tiny md:!text-2sm';

const kycCards = [
  {
    id: 0,
    image: '/img/no-code.svg',
    title: 'No code solutions',
    text:
      'Just enter the required details manually or upload via an excel sheet and get validations report in under 5-sec.',
  },
  {
    id: 1,
    title: 'Unified Dashboard',
    image: '/img/unified-dashboard.svg',
    text:
      'Under a unified dashboard, all your past verifications will be accessible.',
  },
  {
    id: 2,
    title: 'Reporting',
    image: '/img/reporting.svg',
    text:
      'Get all the verifications reports under the Reports tab. You can also download the report and view the different verification statuses. ',
  },
];
function IntegrationSection({ checkList, heading, subHeading, ...props }) {
  return (
    <section className="integration-section-kyc p-section-2" {...props}>
      <img
        src="/img/integration-bg.svg"
        className="parallax-img parallax-img-2 invisible md:visible select-none"
        alt=""
      />
      <img
        src="/img/bav/simple-triangle.svg"
        className="parallax-img-mobile md:hidden select-none"
        alt=""
      />
      <div className="container position-relative">
        <div className="single-dev-block !pb-2 md:!pb-[94px]">
          <span className="block text-sm font-semibold text-cf-white pl-5 mb-2 uppercase relative before:bg-cf-green before:h-[10px] before:w-[10px] before:absolute before:top-[4px] before:left-[4px]">
            INTEGRATION
          </span>
          <span className="block font-heading text-lg md:text-xl font-semibold text-cf-white mb-7.5x max-w-[550px]">
            {heading}
          </span>
          <div className="text-cf-cold-purple pt-4 max-w-[600px] text-2sm md:text-md">
            {subHeading}
          </div>
          <ul className="check-list-round max-w-[600px] text-2sm md:text-md">
            {checkList.map(({ id, item }) => (
              <li key={id}>
                <span className="mt-0.5">
                  <Icon
                    name="tick"
                    width="9.21px"
                    height="6.29px"
                    fill="#fff"
                  />
                </span>
                <p>{item}</p>
              </li>
            ))}
          </ul>

          <a
            href="https://docs.cashfree.com/reference/pg-new-apis-endpoint"
            className="button button-green w-full md:w-auto md:min-w-[278px]">
            View API documentation <span className="chevron" />
          </a>

          <div className="terminal-asset mt-[40px] md:mt-[60px]">
            <div className="terminal-tabs before:!w-[calc(100%-68px)] md:before:!w-[calc(100%-150px)]">
              <span className="!min-w-[68px] md:!min-w-[150px] active">
                Node JS
              </span>
              <span className="!min-w-[68px] md:!min-w-[150px] rounded-bl">
                Java
              </span>
              <span className="!min-w-[68px] md:!min-w-[150px] ">Python</span>
              <span className="!min-w-[68px] md:!min-w-[150px] ">PHP</span>
              <span className="!min-w-[68px] md:!min-w-[150px] ">cURL</span>
            </div>

            <div className="terminal-body !py-4 !px-6 md:!py-6">
              <div className="terminal-content">
                <p className={terminalLine} data-line-number="1">
                  <Code
                    noScroll
                    language="language-javascript"
                    code={`
      const cfSdk = require('cashfree-sdk');
      const {Validation} = cfSdk.Payouts;
      const response = await Validation.ValidateBulkBankActivation({
            bulkValidationId : "testis1",
            entries : [
                      { name: "John Doe", 
                        bankAccount : "1007766300076281" , 
                        ifsc : "HDFC0001007",
                        phone : "9876553210",
                      }
                      ],
            }];
            
            
      `}
                  />
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="integration-slider-card white-dots mt-10 md:mt-0 md:mx-[-19px]">
          <Slider
            {...{
              dots: true,
              arrows: false,
              infinite: false,
              speed: 500,
              slidesToShow: 3,
              responsive: [
                {
                  breakpoint: 768,
                  settings: {
                    slidesToShow: 1,
                    infinite: false,
                    arrows: false,
                    dots: true,
                  },
                },
              ],
            }}>
            {kycCards.map(({ id, image, title, text }) => (
              <div key={id}>
                <div className="w-full md:px-[19px] flex flex-col-reverse md:flex-col">
                  <img
                    src={image}
                    alt=""
                    width="286"
                    height="270"
                    className="ml-0 max-w-[286px] md:max-w-full"
                  />
                  <div className="md:mt-4 md:mb-3">
                    <div className="text-white font-semibold text-[16px] leading-6 pr-6 md:pr-0">
                      {title}
                    </div>
                    <div className="text-2sm md:text-base text-cf-cold-purple mt-2 md:mt-3 mb-7 md:mb-0">
                      {text}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
        <div className="mt-[32px] md:mt-0">
          <a
            href="https://docs.cashfree.com/reference/pg-new-apis-endpoint"
            className="button button-green w-full md:w-auto md:min-w-[278px] mt-12 mx-auto">
            View Documentation <span className="chevron" />
          </a>
        </div>
      </div>
    </section>
  );
}
IntegrationSection.defaultProps = {
  checkList: [],
  heading: (
    <>
      Simple and quick integration{' '}
      <h2 className="inline">KYC Verification API</h2>
    </>
  ),
  subHeading:
    'With 99.98% system uptime, reduce user onboarding time by up to 85% and make payouts to the right beneficiary account. Integrate Cashfree verification API with your pre-existing ecosystem.',
};
IntegrationSection.propTypes = {
  checkList: PropType.arrayOf(
    PropType.shape({
      id: PropType.oneOfType([PropType.string, PropType.number]),
      item: PropType.oneOfType([PropType.string, PropType.node]),
    }),
  ),
  heading: PropType.oneOfType([PropType.string, PropType.node]),
  subHeading: PropType.oneOfType([PropType.string, PropType.node]),
};
export default IntegrationSection;

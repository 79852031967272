import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import debounce from '../../utils/debounce';
import './OtherProducts.scss';

const OtherProducts = ({ data, heading }) => {
  const containerRef = useRef();
  useEffect(() => {
    const resize = debounce(() => {
      if (!containerRef.current) return;
      const containers = containerRef.current.querySelectorAll(
        '.single-product-container',
      );
      let maxHeight = 150;

      containers.forEach((container) => {
        const { height } = getComputedStyle(container);
        maxHeight = Math.max(maxHeight, parseInt(height, 10));
      });
      containers.forEach((container) => {
        if (container) {
          // eslint-disable-next-line no-param-reassign
          container.style.minHeight = `${maxHeight}px`;
        }
      });
    });
    resize();
    window.addEventListener('resize', resize);
    return () => window.removeEventListener('resize', resize);
  }, []);
  return (
    <div className="other-products p-section-1 !pb-10" ref={containerRef}>
      <div className="container">
        <div
          className="font-heading text-lg md:text-xl"
          style={{ marginBottom: '40px' }}>
          {heading}
        </div>
        <div className="row flex-wrap mobile-wrap items-stretch mx-[-5%] md:mx-[-20px]">
          {(
            data || [
              {
                id: 0,
                title: 'Payment Links',
                body:
                  'No-code payment links to collect payments over WhatsApp, SMS, Facebook, Twitter and other channels',
                url: 'https://www.cashfree.com/payment-links',
                iconname: '/img/icons/payment-links.svg',
              },
              {
                id: 1,
                title: 'Subscriptions',
                body:
                  'Accept recurring payments by auto-debiting customers’ accounts via standing instructions on card, e-mandate via net banking option or UPI AutoPay. ',
                url: 'https://www.cashfree.com/recurring-payment',
                iconname: '/img/icons/subscription.svg',
              },
              {
                id: 2,
                title: 'Easy Split ',
                body:
                  'Split vendor commissions and manage marketplace settlements',
                url:
                  'https://www.cashfree.com/easy-split/split-payment-gateway',
                iconname: '/img/icons/easy-split.svg',
              },
              {
                id: 3,
                title: 'AutoCollect ',
                body:
                  'Collect and reconcile IMPS/NEFT/UPI payments using unique virtual accounts and UPI IDs.',
                url: 'https://www.cashfree.com/auto-e-collect',
                iconname: '/img/icons/auto-collect.svg',
              },
            ]
          ).map(({ id, title, body, url, iconname }) => (
            <div
              className="w-full md:w-1/2 px-5 single-product-container"
              key={id}>
              <div className="single-other-product !px-6 !py-4 md:!py-6">
                <img src={iconname} alt={title} loading="lazy" />
                <div className="other-products-text">
                  <div>
                    <h3 className="font-bold md:font-semibold">{title}</h3>
                    <p className="text-2.5sm">{body}</p>
                  </div>
                  {url && (
                    <a
                      href={url}
                      className="button button-unstyled btn p-0 !text-cf-green group left-[83px] !right-auto">
                      Learn More
                      <svg
                        className="!w-[6px] !h-[14px] ml-2.5 group-hover:translate-x-[3px] transition-transform duration-300"
                        width="6"
                        height="11"
                        viewBox="0 0 6 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M1 1L5 5.5L1 10"
                          stroke="#05C16E"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </a>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

OtherProducts.defaultProps = {
  data: null,
  heading: 'Explore other products',
};

OtherProducts.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      body: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      url: PropTypes.string,
      iconname: PropTypes.string,
    }),
  ),
  heading: PropTypes.string,
};

export default OtherProducts;

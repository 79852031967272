import React from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import SliderButton from '../../SliderEasySplit/SliderButton';
import './UseCaseSlider.scss';

const merchants = [
  {
    id: 0,
    image: {
      src: '/img/zomato-white.svg',
      width: 151,
    },
    background: '#E23744',
    title: 'Seamless delivery partner onboarding for Zomato',
    description:
      'Zomato ensures quick and correct on-boarding of riders using Cashfree’s API.',
  },
  {
    id: 1,
    image: {
      src: '/img/bookmyforex-white.svg',
    },
    background: '#06497D',
    title: 'Instant verification of money exchangers for Bookmyforex',
    description:
      'The foreign currency exchange platform uses Cashfree Payments for instant bank verification of money exchangers. As a platform facilitating 1600 crores+ currency exchange transactions, accuracy and speed while verifying accounts is a business prerequisite.',
  },
  {
    id: 2,
    image: {
      src: '/img/zilra.png',
      width: '76',
      height: '33',
    },
    background: '#20A6FC',
    title: 'No cheque bank a/c verification for Zilra',
    description:
      'Cross-border payments processor Zilra no more needs scanned copies of cancelled cheques. It now uses Cashfree Payments to verify the account details in a paperless manner for onboarding freelance partners.',
  },
  // {
  //   id: 3,
  //   image: {
  //     src: '/img/signzy-logo.png',
  //   },
  //   background: '#000000',
  //   title: 'Reliable E-KYC for Signzy',
  //   description:
  //     'Signzy trusts Cashfree Payments for reliable and real-time verification of bank account numbers as a part of its KYC suite.',
  // },
  {
    id: 4,
    image: {
      src: '/img/bajajfinserv-logo.png',
      width: '140',
      height: '44',
    },
    background: '#0071BB',
    title: 'Automated User KYC and bank account check',
    description:
      'Baja Finance verifies it’s customers before activating any of their products like loans, insurance, FD’s or before loan disbursals for personal, business or professional requirement.',
  },
];

function UseCaseSlider({ data, settings, grey }) {
  return (
    <div className="use-case-slider-slick">
      <Slider {...settings}>
        {data.map((d) => (
          <div key={d.id} className="w-full p-5 pt-0 !flex h-full group">
            <div
              className={`flex flex-col ${
                grey ? 'bg-cf-light-grey' : 'bg-cf-white'
              }  transition-all duration-300 md:hover:shadow-card`}>
              <div
                className="min-h-[232px] relative flex justify-center items-center"
                style={{ background: d.background }}>
                <img alt="" {...d.image} />
              </div>
              <div
                className={`${
                  grey ? 'bg-cf-light-grey' : 'bg-cf-white'
                } px-4 pb-4 md:px-6 md:pb-6`}>
                <div className="font-semibold text-2md pt-4 pb-4">
                  {d.title}
                </div>
                <div className="text-2.5sm">{d.description}</div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}

UseCaseSlider.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      image: PropTypes.shape({}),
      title: PropTypes.string,
      background: PropTypes.string,
      description: PropTypes.string,
    }),
  ),
  grey: PropTypes.bool,
  settings: PropTypes.shape({}),
};

UseCaseSlider.defaultProps = {
  data: merchants,
  grey: false,
  settings: {
    dots: true,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    // slidesToScroll: 1,
    nextArrow: <SliderButton left={false} />,
    prevArrow: <SliderButton />,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.2,

          infinite: false,
          arrows: false,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          infinite: false,
          arrows: false,
          dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,

          infinite: false,
          arrows: true,
          dots: true,
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 4,

          infinite: false,
          arrows: true,
          dots: true,
        },
      },
    ],
  },
};

export default UseCaseSlider;

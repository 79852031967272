import React, { useRef } from 'react';
import Icon from '../components/Icon';
import Layout from '../components/Layout';
import Tabs from '../components/Tabs/Tabs';
import SEO from '../components/SEO';
import IntegrationSection from '../components/Sections/IntegrationSection/IntegrationSection';
import GetStarted from '../components/GetStarted';
import FAQ from '../components/FAQ';
import OtherProducts from '../components/OtherProducts/OtherProducts';
import Scrollspy from '../components/Scrollspy';
import HeroLogoSlider from '../components/Sliders/HeroLogoSlider/HeroLogoSlider';
import SuiteSlider from '../components/Sliders/SuiteSlider/SuiteSlider';
import UseCaseSlider from '../components/Sliders/UseCaseSlider/UseCaseSlider';
import CheckList from '../components/CheckList/CheckList';
import {
  heroCheckList,
  heroLogoData,
  heroSliderSettings,
  verificationSuiteData,
  usecasesData,
  instantKycCard,
  instantKycCheckList,
  integrationCheckList,
  faqData,
  otherProducts,
} from '../content/verification-suite';
import '../styles/verification-suite.scss';
import TransparentVideo from '../components/TransparentVideo/TransparentVideo';

function VerificationSuitePage({ ...rest }) {
  const container = useRef();
  return (
    <Layout
      lightHeader
      {...rest}
      signInLink="https://merchant.cashfree.com/merchants/login?source-action=Verification%20Suite&action=Sign%20In&button-id=SignIn_Navbar"
      createAccountLink="https://merchant.cashfree.com/merchants/signup?source-action=Verification%20Suite&action=Sign%20Up&button-id=StartNow_Navbar">
      <SEO
        title="The Complete KYC Verification Suite | Cashfree Payments"
        description="With KYC Verification Suite, verify the bank account details, PAN, Aadhaar, and GSTIN. Recommended for identity verification for individuals or businesses during onboarding and to ensure accurate payouts every time."
        url="https://www.cashfree.com/kyc-verification/"
        keywords={[
          'KYC verification',
          'Identity Verification',
          'Background Verification',
          'Employee verification',
          'Vendor verification',
          'User verification',
          'Business Verification',
          'Fraud Detection',
          'Machine Learning',
          'Artificial Intelligence',
          'Fraud and risk management',
          'Risk management',
          'Customer Onboarding',
        ]}
      />

      <section className="relative section-padding bg-cf-primary verification-suite-hero-section overflow-hidden text-white">
        <div className="container relative">
          <div className="flex flex-wrap flex-col lg:flex-row lg:flex-nowrap w-full lg:mb-[20px] lg:w-[115%]">
            <div className="relative z-[1] w-full lg:w-7/12 lg:mt-[70px]">
              <h2 className="text-sm font-semibold text-white pl-5 mb-[10px] uppercase relative before:bg-cf-green before:h-[10px] before:w-[10px] before:absolute before:top-[4px] before:left-[4px] font-body">
                Verification Suite
              </h2>
              <div className="mb-3 md:mb-5 text-xl md:text-2xl font-semibold max-w-[500px] font-heading">
                Complete <h1 className="inline">KYC Verification Suite</h1>
              </div>
              <div className="text-opacity-80 mb-6 md:mb-[30px] max-w-[620px] text-[16px] leading-[24px] md:text-2.5md">
                A dynamic and compliant{' '}
                <h2 className="font-body inline">KYC verification solution</h2>{' '}
                for your business. Automate user or vendor KYC and eliminate
                fraud using an updated database in real-time using powerful
                APIs.
              </div>
              <div className="text-2md font-semibold">
                Single &{' '}
                <h2 className="font-body inline">bulk KYC verification</h2>
              </div>
              <ul className="p-0 mt-3 md:mt-4 mb-[15px] md:mb-[25px] list-none max-w-full flex flex-col md:flex-row md:items-center justify-start">
                {heroCheckList.map(({ id, listItem }) => (
                  <li
                    className="mb-[15px] mr-[24px] md:mr-[16px] last:mr-0 flex items-center"
                    key={id}>
                    <span className="w-5 h-5 flex flex-none justify-center items-center rounded-full mr-2 bg-white bg-opacity-25">
                      <Icon
                        name="tick"
                        width="9.21px"
                        height="6.29px"
                        fill="#fff"
                      />
                    </span>
                    <h3 className="font-body !m-0 text-2.5sm md:text-md">
                      {listItem}
                    </h3>
                  </li>
                ))}
              </ul>
              <div className="flex flex-wrap md:flex-nowrap justify-between md:justify-start mb-[48px] lg:mb-10 ">
                <a
                  className="button button-green w-full md:w-auto md:mr-4"
                  href="https://merchant.cashfree.com/merchants/signup?source-action=Verification%20Suite&action=Sign%20Up&button-id=StartNow_Hero">
                  Create Account <span className="chevron" />
                </a>
                <a
                  className="button button-outline no-shadow text-white border-cf-stroke w-full md:w-auto mt-4 md:mt-0"
                  href="https://www.cashfree.com/contact-sales?source-action=Verification%20Suite&action=Contact%20Sales&button-id=ContactSales_Hero">
                  Contact Sales <span className="chevron !mb-0" />
                </a>
              </div>
            </div>
            <div className="w-full lg:w-5/12 lg:self-center  lg:pl-[19px] flex justify-center h-full items-center">
              <TransparentVideo
                backgroundRef={container}
                className="relative z-10"
                poster="/img/verification-suite/poster.png"
                width="1400"
                height="1120"
                mp4Src="/img/verification-suite/hero-video.mp4"
                webmSrc="/img/verification-suite/hero-video.webm"
              />
            </div>
          </div>
        </div>
        <section className="logo-container mt-12 lg:mt-[76px]">
          <div className="relative overflow-hidden z-10">
            <HeroLogoSlider
              data={heroLogoData}
              imgUrl="/img/slider-logo"
              className="pl-[10px]"
              itemClassName="mx-[25px] md:mx-[36px] flex items-center align-center h-[48px]"
              settings={heroSliderSettings}
            />
          </div>
        </section>
      </section>
      <Scrollspy
        centeredLinks
        alwaysCenter
        leadContent={
          <div
            className="payout-nav-logo row row-no-margin justify-flex-start"
            style={{ minWidth: '190px' }}>
            <img
              src="/img/icons/verification-suite-icon.svg"
              alt=""
              width="20"
              height="19"
            />
            <span>KYC Verification Suite</span>
          </div>
        }>
        <section
          className="p-section-3"
          data-spy-title="Features"
          data-spy-id="features">
          <div className="container">
            <div className="text-shs md:text-shl font-semibold max-w-[495px] font-heading mb-5">
              Comprehensive Identity Verification
            </div>
            <div className=" mb-6 md:mb-8 max-w-[750px]">
              Embed Cashfree Payments complete{' '}
              <h2 className="font-body inline">KYC Verification Suite</h2> into
              your product and verify the identity of your users or partners in
              real-time during onboarding, before making payouts or disbursing
              refunds.
            </div>
            <Tabs data={verificationSuiteData} dropDown timerAutoPlay />
          </div>
        </section>
        <section className="p-section-2 overflow-hidden bg-cf-light-grey">
          <div className="container">
            <div className="text-shs md:text-shl font-semibold max-w-[650px] font-heading mb-10 md:mb-16">
              Who can use <h2 className="inline">KYC Verification Suite</h2> by
              Cashfree Payments?
            </div>
            <SuiteSlider />
          </div>
        </section>
        <section className="p-section-2 overflow-hidden">
          <div className="container">
            <p className="text-sm font-semibold text-cf-primary pl-5 mb-2 uppercase relative before:bg-cf-green before:h-[10px] before:w-[10px] before:absolute before:top-[4px] before:left-[4px] text-center mx-auto max-w-max">
              Use cases for various industries
            </p>
            <span className="block font-heading text-lg md:text-xl font-semibold text-cf-hero mb-3 md:mb-5 text-center">
              Verification Suite for innovative businesses
            </span>
            <p className="text-2sm md:text-md mb-[32px] md:mb-16 text-center">
              Top businesses across industries trust Cashfree Payments&apos;
              Verification Suite
            </p>
            <div className="md:mb-[48px]">
              <UseCaseSlider data={usecasesData} grey />
            </div>
          </div>
        </section>
        <section className="pt-12 md:pt-32 pb-12 md:pb-[140px] overflow-hidden bg-cf-light-grey">
          <div className="container">
            <h2 className="text-sm font-body font-semibold text-cf-primary pl-5 mb-2 uppercase relative before:bg-cf-green before:h-[10px] before:w-[10px] before:absolute before:top-[4px] before:left-[4px]">
              Instant KYC Verification
            </h2>
            <div className="block font-heading text-lg md:text-xl font-semibold  mb-5 max-w-[778px]">
              Powerful and reliable AI models enabling seamless{' '}
              <h2 className="inline">KYC verification</h2>
            </div>
            <CheckList
              data={instantKycCheckList}
              itemMargin="mb-4 mr-8"
              className="mb-12 md:mb-[60px] flex flex-col md:flex-row flex-wrap"
            />
            <div className="grid grid-cols-1 md:grid-cols-3 mx-0 md:mx-[-19px] gap">
              {instantKycCard.map(({ id, description, heading, icon }) => (
                <div
                  key={id}
                  className="w-full px-0 md:px-[19px] mb-4 md:mb-[40px]">
                  <div className="bg-white px-4 py-6 h-full rounded min-h-[260px] md:min-h-0">
                    <div className="w-[32px] md:w-[40px] h-[32px] md:h-[40px] mb-5 md:mb-8">
                      <img className="w-full" src={icon} alt="" />
                    </div>
                    <div>
                      <div className="font-body font-bold md:font-semibold mb-2">
                        {heading}
                      </div>
                      <div className="text-2sm">{description}</div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
        <IntegrationSection
          data-spy-title="Integration"
          data-spy-id="integration"
          subHeading="With 99.98% system uptime, reduce user onboarding time by up to 85% and make payouts to the right beneficiary account. Integrate Cashfree verification API with your pre-existing ecosystem. "
          checkList={integrationCheckList}
        />
      </Scrollspy>
      <section>
        <div className="container general-faqs">
          <FAQ
            open="0"
            data={faqData}
            supportLinkHref="https://www.cashfree.com/help/hc"
          />
        </div>
      </section>
      <OtherProducts data={otherProducts} />
      <GetStarted
        startNowLink="https://merchant.cashfree.com/merchants/signup?source-action=Verification%20Suite&action=Sign%20Up&button-id=StartNow_Footer"
        getInTouchLink="https://www.cashfree.com/contact-sales?source-action=Verification%20Suite&action=Contact%20Sales&button-id=ContactSales_Footer"
      />
    </Layout>
  );
}

export default VerificationSuitePage;
